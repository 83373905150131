
import { defineComponent } from 'vue'
import { PropType } from 'vue/types/v3-component-props'

import Toggle from '@/components/toggle/toggle.vue'

export interface SidebarOpt {
  template: string
  sendType: 'automated' | 'manual'
  domainId?: string
  updated?: boolean
}

export default defineComponent({
  name: 'notoification-sidebar',
  components: { Toggle },
  data () {
    return {
      isCollapsed: false
    }
  },
  props: {
    options: {
      type: Array as PropType<SidebarOpt[]>,
      required: false,
      default () {
        return []
      }
    }
  },
  methods: {
    onChange (e: InputEvent) {
      const templateId = (e.target as HTMLInputElement | null)?.id

      const option = this.options.find(({ template }) => template === templateId)
      const changedOption = {
        ...option,
        sendType: option?.sendType === 'automated' ? 'manual' : 'automated'
      }

      this.$emit('change', changedOption)
    }
  }
})


import { defineComponent } from 'vue'

import { PropType } from 'vue/types/v3-component-props'

export default defineComponent({
  name: 'Checkbox',
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      required: false
    },
    icon: {
      type: String as PropType<'checkmark' | 'minus'>,
      validator (val: string) {
        return ['checkmark', 'minus'].includes(val)
      },
      default: 'checkmark',
      required: false
    }
  },
  methods: {
    onChange (e: Event) {
      this.$emit('change', e)
      this.$emit('input', (e.target as HTMLInputElement).checked)
    }
  }
})


import { Portal } from 'portal-vue'
import { defineComponent } from 'vue'
import { PropType } from 'vue/types/v3-component-props'

import Input from './components/input.vue'
import Checkbox from '../checkbox/checkbox.vue'

interface Opt {
  key: string;
  count: number;
  label?: string;
}

export default defineComponent({
  name: 'multiselect-with-render-limit',
  components: {
    Checkbox,
    Input,
    Portal
  },
  props: {
    id: {
      type: String,
      required: true
    },
    portalId: {
      type: String,
      required: true
    },
    menuIsOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    anchorLabel: {
      type: String,
      required: true
    },
    options: {
      type: Array as PropType<Opt[]>,
      required: true
    },
    value: {
      type: Array as PropType<Opt[]>,
      required: false,
      default () {
        return []
      }
    },
    sort: {
      type: String as PropType<'' | 'asc' | 'desc'>,
      required: false,
      validator: (val: string) => ['asc', 'desc', ''].includes(val),
      default: ''
    },
    renderLimit: {
      type: Number,
      required: false,
      default: 1000
    },
    sortOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isOpen: this.menuIsOpen,
      search: '',
      wheelOpt: null as any,
      wheelEvent: null as any,
      containerLeft: 0,
      containerTop: 0
    }
  },
  mounted () {
    document.addEventListener('mousedown', this.onClickOutside)
    window.addEventListener('resize', this.getContainerPosition)
  },
  beforeDestroy () {
    document.removeEventListener('mousedown', this.onClickOutside)
    window.removeEventListener('resize', this.getContainerPosition)
  },
  watch: {
    menuIsOpen: function (newVal) {
      if (newVal !== this.isOpen) {
        this.isOpen = newVal
      }
    }
  },
  computed: {
    filteredOptions (): Opt[] {
      return this.options
        .filter((opt) =>
          opt.key.toLowerCase().startsWith(this.search.toLowerCase()) || opt.label?.toLowerCase().startsWith(this.search.toLowerCase())
        )
        .sort((a: Opt, b: Opt) => {
          const isASelected = this.value.some(
            ({ key }) => key === a.key
          )
          const isBSelected = this.value.some(
            ({ key }) => key === b.key
          )

          const alphSort = a.key.toLowerCase().localeCompare(b.key.toLowerCase())

          if (isASelected && !isBSelected) {
            return -1
          } else if (!isASelected && isBSelected) {
            return 1
          } else {
            return alphSort
          }
        })
    },
    selectAllIcon (): 'checkmark' | 'minus' {
      return this.value.length === this.options.length ? 'checkmark' : 'minus'
    },
    optCount (): number {
      return this.value.reduce((acc, curr) => acc + curr.count, 0)
    },
    totalCount (): number {
      const totalCount = this.filteredOptions?.length ?? 0
      console.log({ totalCount })
      return totalCount
    }
  },
  methods: {
    toggleMenu (newVal?: boolean) {
      const isOpen = newVal || !this.isOpen

      this.$emit('menuToggle', isOpen)
      this.isOpen = isOpen
      this.getContainerPosition()
    },

    onClickOutside (e: MouseEvent) {
      this.$nextTick()
      const dropdown = this.$refs.dropdown as HTMLDivElement | null
      const anchorButton = this.$refs.anchorButton as HTMLButtonElement | null

      const isOutside = !dropdown?.contains(e.target as Node) && !anchorButton?.contains(e.target as Node)

      if (this.isOpen && isOutside) {
        this.toggleMenu(false)
        this.search = ''
      }
    },
    onSelectAll () {
      const newVal = this.value.length === 0 ? this.options : []

      this.$emit('input', newVal)
    },
    onSelect (e: InputEvent) {
      const optId = (e.target as HTMLInputElement).id.replace(`${this.id}-`, '')

      const opt = this.options.find(
        ({ key }) => key === optId
      )

      if (opt) {
        const newVal = (e.target as HTMLInputElement).checked
          ? [...this.value, opt]
          : this.value.filter(({ key }) => key !== opt.key)

        this.$emit('input', newVal)
      }
    },
    onSort () {
      let sort

      if (this.sort === '') {
        sort = 'asc'
      } else if (this.sort === 'asc') {
        sort = 'desc'
      } else {
        sort = ''
      }

      this.$emit('sort', { sortField: this.id, sortDir: sort })
    },
    getContainerPosition () {
      const { bottom, left, right } = (this.$refs.anchorButton as HTMLButtonElement).getBoundingClientRect()

      const dropdownWidth = 246

      this.containerLeft = window.scrollX + left + dropdownWidth > window.innerWidth ? window.scrollX + right - dropdownWidth : window.scrollX + left
      this.containerTop = window.scrollY + bottom + 16
    }
  }
})

<template>
    <div>
        <b-tabs v-model="activeTab" class="outbox--tabs" id="outbox-tabs" type="is-toggle" @input="selectTab(activeTab)">
            <b-tab-item label="Automated"></b-tab-item>
            <b-tab-item label="Manual"></b-tab-item>
            <b-tab-item label="Sent" class="sent-tab"></b-tab-item>
            <b-tab-item headerClass="error" v-if="isErrorTabEnabled" label="Errors"></b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
export default {
  props: {
    isErrorTabEnabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeTab: 0
    }
  },
  methods: {
    selectTab (tab) {
      this.$emit('selectTab', tab)
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .outbox--tabs#outbox-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;

    .tabs.is-toggle {
        .sent-tab {
          border-radius: 0 4px 4px 0;
        }

        a {
          border-color: #5586EF;
          color: #5586EF;
          width: 7rem;
          height: 50px;
        }

        li.is-active a {
          background-color: #5586EF;
          color: #fff;
        }

        li:first-of-type > a {
          border-radius: 4px 0 0 4px;
        }

        li.error {
          border-radius: 4px;
          margin-left: 0.75rem;
          background-color: #ff5858;

          a, a:hover {
            border-radius: 4px;
            border-color: red;
            width: fit-content;
            color: white;

            &:hover {
              background-color: red;
            }
          }
        }
        li.error.is-active > a {
          background-color: red;
        }
    }
}

</style>

export enum NotificationRowModalMessage {
    AUTOMATE_TITLE = 'Are you sure you want to automate this notification?',
    AUTOMATE_TEXT = 'Automating will move this notification to the Automated tab. This notification will be released along with the batch.',
    AUTOMATE_BTN = 'Yes, automate',
    AUTOMATE_BULK_TITLE = 'Are you sure you want to automate?',
    AUTOMATE_BULK_TEXT = 'Automating will move all notifications currently displayed in the table to the Automated tab. These notifications will be released along with the batch.',
    AUTOMATE_BULK_BTN = 'Yes, automate',
    MANUAL_TITLE = 'Are you sure you want to Pause?',
    MANUAL_TEXT = 'Pausing will move this notification to the Manual tab. This notification will not be released until re-added to a batch.',
    MANUAL_BTN = 'Yes, pause',
    MANUAL_BULK_TITLE = 'Are you sure you want to Pause?',
    MANUAL_BULK_TEXT = 'Pausing will move all notifications currently displayed in the table to the Manual tab. These notifications will not be released until re-added to a batch.',
    MANUAL_BULK_BTN = 'Yes, pause',
    SEND_TITLE = 'Are you sure you want to send this notification?',
    SEND_TEXT = 'This will send this notification immediately.',
    SEND_BTN = 'Yes, send now',
    SEND_BULK_TITLE = 'Are you sure you want to send these notifications now?',
    SEND_BULK_TEXT = 'This will send all notifications displayed in the table immediately.',
    SEND_BULK_BTN = 'Yes, send now',
    DELETE_TITLE = 'Are you sure you want to delete?',
    DELETE_TEXT = 'This will delete this notification immediately.',
    DELETE_BTN = 'Yes, delete',
    DELETE_BULK_TITLE = 'Are you sure you want to delete?',
    DELETE_BULK_TEXT = 'Bulk deleting will cancel all notifications currently displayed in the table. They cannot be re-queued from within the Outbox.',
    DELETE_BULK_BTN = 'Yes, delete'
}

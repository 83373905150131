<template>
<main class="notifications-queue--content">
  <notification-sidebar
    :options="notificationTemplateList"
    @change="onChangeSendType"
  />
  <div class="notifications-queue--root">
    <b-loading :is-full-page="true" v-model="gridLoading"></b-loading>
    <portal-target name="notification-queue" multiple />

    <div class="notifications-queue--header">

      <div class="notifications-queue--subheader tabs-and-search">
        <div>
          <OutboxTabs :is-error-tab-enabled="isErrorTabEnabled" @selectTab="onChangeTab"></OutboxTabs>
        </div>

        <div class="notifications-queue--search">
          <b-input
            size="is-medium"
            class="search-input"
            placeholder="Search client or template"
            v-model="gridSearchCriteria"
            icon-pack="fas"
            icon="search"
            icon-clickable
            @icon-click="searchClientOrTemplate"
            @input="searchClientOrTemplate"/>
        </div>
      </div>

      <div class="notifications-queue--subheader">
        <div class="count-and-scheduled">
          <div
            id="totalCount"
            class="count-and-scheduled"
          >
            Total Count:
            <span> {{ totalCount }} </span>
          </div>

          <div
            id="scheduledDate"
            class="count-and-scheduled"
            v-if="scheduledDate"
          >
            Scheduled:
            <span> {{ scheduledDate }} </span>
          </div>
        </div>

        <div>
          <button class="reset-filter--button" @click="resetFilters">Reset All Filters</button>
          <b-button
            size="16"
            label="Refresh list"
            class="refresh-list no-border"
            icon-pack="fas"
            icon-right="sync"
            @click="getNotifications"/>
        </div>
      </div>
      <div>

      </div>
    </div>
    <b-table
      ref="notificationTable"
      :class="{ 'notifications-queue-is-empty': !list || list.length === 0, 'notifications-queue--table': true }"
      :data="list"
      sticky-header
      bordered
      detailed
      :row-class="getParentRowStyle"
      :opened-detailed="detailRowOpened"
      :show-detail-icon="false"
      @details-open="(row) => onDetailsRowOpen(row)"
      @details-close="onDetailsRowClose()"
    >
      <b-table-column field="clientName" label="Client" :td-attrs="getParentCellStyle">
        <template v-slot:header="{ column }" v-if="!this.isBulkOptionsDisabled">
          <MultiselectWithRenderLimit
            id="client"
            :anchorLabel="column.label"
            :options="gridLoading && menuToggle ? [] : aggregations.client"
            :sort="sortAttributes.sortField === 'client' ? sortAttributes.sortDir : ''"
            v-model="filterParams.client"
            @input="searchByMultiselect"
            @sort="onSort"
            portalId="notification-queue"
          />
        </template>
        <template v-slot="props">
            <span class="client-row-click">
              <a @click="props.toggleDetails(props.row)">
                {{ getFormattedString(props.row.client) }}
              </a>
            </span>
        </template>
      </b-table-column>

      <b-table-column field="templateName" label="Template">
        <template v-slot:header="{ column }" v-if="!this.isBulkOptionsDisabled">
          <MultiselectWithRenderLimit
            id="NotificationsTemplateName"
            :anchorLabel="column.label"
            :options="gridLoading && menuToggle ? [] : aggregations.NotificationsTemplateName"
            :sort="sortAttributes.sortField === 'NotificationsTemplateName' ? sortAttributes.sortDir : ''"
            v-model="filterParams.NotificationsTemplateName"
            @input="searchByMultiselect"
            @sort="onSort"
            portalId="notification-queue"
          />
        </template>
        <template v-slot="props">
          <span>
            {{ getFormattedString(props.row.NotificationsTemplateName) }}
          </span>
        </template>
      </b-table-column>

      <b-table-column field="type" label="Type">
        <template v-slot:header="{ column }" v-if="!this.isBulkOptionsDisabled">
          <MultiselectWithRenderLimit
            id="type"
            :anchorLabel="column.label"
            :options="gridLoading && menuToggle ? [] : aggregations.type"
            :sort="sortAttributes.sortField === 'type' ? sortAttributes.sortDir : ''"
            v-model="filterParams.type"
            @input="searchByMultiselect"
            @sort="onSort"
            portalId="notification-queue"
          />
        </template>
        <template v-slot="props">
          <span>
            {{ getFormattedString(props.row.type) }}
          </span>
        </template>
      </b-table-column>

      <b-table-column field="planName" label="Plan">
        <template v-slot:header="{ column }" v-if="!this.isBulkOptionsDisabled">
          <MultiselectWithRenderLimit
            id="plan"
            :anchorLabel="column.label"
            :options="gridLoading && menuToggle ? [] : aggregations.plan"
            :sort="sortAttributes.sortField === 'plan' ? sortAttributes.sortDir : ''"
            v-model="filterParams.plan"
            @input="searchByMultiselect"
            @sort="onSort"
            portalId="notification-queue"
          />
        </template>

        <template v-slot="props">
          <span>
            {{ getFormattedString(props.row.plan) }}
          </span>
        </template>
      </b-table-column>

      <b-table-column field="memberName" label="Member Name">
        <template v-slot:header="{ column }" v-if="!this.isBulkOptionsDisabled">
          <MultiselectWithRenderLimit
            id="recipientLastName"
            :anchorLabel="column.label"
            :options="gridLoading && menuToggle ? [] : aggregations.recipientLastName"
            :sort="sortAttributes.sortField === 'recipientLastName' ? sortAttributes.sortDir : ''"
            v-model="filterParams.recipientLastName"
            @input="searchByMultiselect"
            @sort="onSort"
            portalId="notification-queue"
          />
        </template>
        <template v-slot="props">
          <span>
            {{ getFormattedString(`${props.row.recipientLastName}, ${props.row.recipientFirstName}`) }}
          </span>
        </template>
      </b-table-column>

      <b-table-column field="eligibilityType" label="Eligibility Type">
        <template v-slot:header="{ column }" v-if="!this.isBulkOptionsDisabled">
          <MultiselectWithRenderLimit
            id="recipientEligibilityType"
            :anchorLabel="column.label"
            :options="gridLoading && menuToggle ? [] : aggregations.recipientEligibilityType"
            :sort="sortAttributes.sortField === 'recipientEligibilityType' ? sortAttributes.sortDir : ''"
            v-model="filterParams.recipientEligibilityType"
            @input="searchByMultiselect"
            @sort="onSort"
            portalId="notification-queue"
          />
        </template>
        <template v-slot="props">
          <span>
            {{ getFormattedString(props.row.recipientEligibilityType) }}
          </span>
        </template>
      </b-table-column>

      <b-table-column field="networkName" label="Network">
        <template v-slot:header="{ column }" v-if="!this.isBulkOptionsDisabled">
          <MultiselectWithRenderLimit
            id="networkName"
            :anchorLabel="column.label"
            :options="gridLoading && menuToggle ? [] : aggregations.networkName"
            :sort="sortAttributes.sortField === 'networkName' ? sortAttributes.sortDir : ''"
            v-model="filterParams.networkName"
            @input="searchByMultiselect"
            @sort="onSort"
            portalId="notification-queue"
          />
        </template>
        <template v-slot="props">
          <span>
            {{ getFormattedString(props.row.networkName) }}
          </span>
        </template>
      </b-table-column>

      <b-table-column field="pcp" label="PCP">
        <template v-slot:header="{ column }" v-if="!this.isBulkOptionsDisabled">
          <MultiselectWithRenderLimit
            id="recipientPcpNpi"
            :anchorLabel="column.label"
            :options="gridLoading && menuToggle ? [] : aggregations.recipientPcpNpi"
            :sort="sortAttributes.sortField === 'recipientPcpNpi' ? sortAttributes.sortDir : ''"
            v-model="filterParams.recipientPcpNpi"
            @input="searchByMultiselect"
            @sort="onSort"
            portalId="notification-queue"
          />
        </template>
        <template v-slot="props">
          <span>
            {{ getFormattedString(props.row.recipientPcpNpi) }}
          </span>
        </template>
      </b-table-column>

      <b-table-column v-if="currentTab === 1 || currentTab === 3" field="created" label="Created">
        <template v-slot:header="{ column }">
          <MultiselectWithRenderLimit
            id="created"
            :anchorLabel="column.label"
            :options="gridLoading && menuToggle ? [] : dateConverter(aggregations.created)"
            :sort="sortAttributes.sortField === 'created' ? sortAttributes.sortDir : ''"
            v-model="filterParams.created"
            @input="searchByMultiselect"
            @sort="onSort"
            portalId="notification-queue"
            sortOnly="true"
          />
        </template>
        <template v-slot="props">
          <span>
            {{ getFormattedDate(props.row.created) }}
          </span>
        </template>
      </b-table-column>

      <b-table-column v-if="currentTab === 2" field="sent" label="Sent">
        <template v-slot:header="{ column }">
          <MultiselectWithRenderLimit
            id="sent"
            :anchorLabel="column.label"
            :options="gridLoading && menuToggle ? [] : dateConverter(aggregations.sent)"
            :sort="sortAttributes.sortField === 'sent' ? sortAttributes.sortDir : ''"
            v-model="filterParams.sent"
            @input="searchByMultiselect"
            @sort="onSort"
            portalId="notification-queue"
            sortOnly="true"
          />
        </template>
        <template v-slot="props">
          <span>
            {{ getFormattedDate(props.row.sent) }}
          </span>
        </template>
      </b-table-column>

      <b-table-column label="Actions" width="80">
        <template v-slot="props">
          <div
            v-if="shouldShowActionIcon(props.row?.sk)"
            class="status-action-wrapper"
          >
            <img
              :class="{ 'spinner': getRowStatus(props.row?.sk).img.includes('loading') }"
              :src="getRowStatus(props.row?.sk).img"
              alt=""
            >
          </div>

          <div v-else class="actions-wrapper">
            <b-field class="column-icons" v-if="!!props.row.sk">
              <div>
                <b-tooltip
                  :label="props.row.type === 'Physical' ? 'Physical letter preview will be available soon' : 'Preview message'"
                  type="is-dark"
                  position="is-left"
                >
                  <button
                    :disabled="props.row.type === 'Physical'"
                    class="button-preview"
                    @click="getNotificationData('previewMessage', props)"
                  >
                    <img src="../assets/eye.svg" alt="Preview message" />
                  </button>
                </b-tooltip>
              </div>
              <div v-show="currentTab !== 2">
                <b-tooltip label="Actions options" type="is-dark" position="is-left">
                  <button
                    ref="actionsOptionsIcon"
                    :disabled="isBulkOptionsDisabled"
                    class="actions-button"
                    @click="showActionOptions($event, props)"
                  >
                    <img src="../assets/icons/more-options-icon.svg" />
                  </button>
                </b-tooltip>
              </div>
            </b-field>
          </div>

          <div v-if="(props.row?.status === 'ERROR' || !!errorRows[props.row.id])" class="row-error-message">
            <div class="trigger"></div>
            <div class="message">
              <img src="../assets/icon-warning-circle.svg" />
              {{ errorRows[props.row.id]?.error ?? props.row.outboxErrorMessage ?? 'An unknown error occurred.' }}
            </div>
          </div>
        </template>
      </b-table-column>

      <template #detail="props">
        <div class="expanded-row-content">
          <span class="row-item-memberid">{{ "Member ID" + '&nbsp;&nbsp;' + props.row.recipientFamilyId }}</span>
          <span class="row-item-pcpname">{{ !props.row.data.PCP_NAME.includes('null') ? "PCP Name" + '&nbsp;&nbsp;' + props.row.data.PCP_NAME : "" }}</span>
        </div>
      </template>
    </b-table>

    <div
      ref="actionsOptionsDropdown"
      v-show="isActionOptionsOpened"
      class="actions-options-container"
      :class="{ 'inverted': isActionOptionsAbove }"
      :style="[
        isActionOptionsAbove
          ? { top: `${topPosition - 260}px`, left: `${leftPosition}px` } // 260 = dropdown height + actions div internal space
          : {top: `${topPosition}px`, left: `${leftPosition}px`}
      ]"
    >
      <img src="../assets/icons/triangle-icon.svg">

      <div>
        <ul
          v-for="option in filteredOptions"
          :key="option.img"
          @click="option.messageType ? onOpenConfirmationModal(option) : option.func()"
        >
          <li class="actions-items">
            <img :src="option.img">
            {{ option.label }}
          </li>
        </ul>
      </div>
    </div>

    <div class="notifications-bulk-actions">
      <ul>
        <li
          v-for="bulkOption in filteredBulkOptions"
          :key="bulkOption.img"
        >
          <button
            :disabled="isBulkOptionsDisabled"
            @click="bulkOption.messageType ? onOpenConfirmationModal(bulkOption) : bulkOption.func()"
          >
            {{bulkOption.label}}
            <img
              v-if="menuStatusImg && currentBulkAction === bulkOption.messageType"
              :src="menuStatusImg"
              :class="{ 'spinner': menuStatusImg.includes('loading') }"
            >
            <img v-else :src="bulkOption.img">
          </button>
        </li>
      </ul>

      <div v-if="bulkResultStatus !== undefined" class="bulk-result" :class="{ [bulkResultStatus]: true }">
        <img v-if="bulkResultStatus === 'error'" src="../assets/icon-warning-circle.svg" />
        <img v-else src="../assets/icon-check-circle.svg" />
        <span>
          {{ bulkResultText }}
        </span>
        <button type="button" @click="bulkResultStatus = undefined">
          <img src="../assets/close.svg" />
        </button>
      </div>
    </div>

    <alert-modal
      v-if="!!modalData"
      :title="modalData.title"
      :text="modalData.text"
      :primaryBtnText="modalData.btn"
      secondaryBtnText="Cancel"
      @primaryBtnClick="modalData.func()"
      @secondaryBtnClick="modalData = null"
    />

    <b-modal class="big-screen-modal" v-model="showPreviewModal">
      <iFrame>
        <div v-html="previewModalContent" />
      </iFrame>
    </b-modal>

    <b-modal class="big-screen-modal" v-model="showJsonModal">
      <json-viewer :value="jsonModalContent" :sort="true" />
    </b-modal>

    <b-modal v-model="showValidationErrorModal">
      <iFrame>
        <div>
          <h4>This notification cannot be sent.</h4>
        </div>
        <div>{{ validationErrorContent }}</div>
      </iFrame>
    </b-modal>
  </div>
</main>
</template>

<script>
import _ from 'lodash'
import { PortalTarget } from 'portal-vue'
import AlertModal from '@/components/alert-modal.vue'
import iFrame from '@/components/i-frame.vue'
import NotificationSidebar from '@/components/notification-sidebar/notification-sidebar.vue'
import OutboxTabs from '@/components/notifications-outbox/notifications-outbox-tabs.vue'
import MultiselectWithRenderLimit from '@/components/multiselect/multiselect-with-render-limit.vue'
// import SortIcon from '@/components/sort-icons.vue'
import store from '@/store/store'
import JsonViewer from 'vue-json-viewer'
import { handleNotificationsRecords, convertToCSV, sanitizeObj, fullDateToDate } from '@/helpers'
import { NotificationRowModalMessage } from '@/types/notifications-outbox'

const aggs = 'client,plan,type,NotificationsTemplateName,recipientEligibilityType,recipientLastName,recipientPcpNpi,networkName,sent,created'

const tabFilters = {
  0: {
    status: [{ key: 'PENDING' }],
    sendType: [{ key: 'automated' }]
  },
  1: {
    status: [{ key: 'PENDING' }],
    sendType: [{ key: 'manual' }]
  },
  2: {
    status: [{ key: 'PROCESSED' }],
    sendType: []
  },
  3: {
    status: [{ key: 'ERROR' }],
    sendType: []
  }
}

export default {
  name: 'notifications-queue',
  components: {
    AlertModal,
    iFrame,
    JsonViewer,
    OutboxTabs,
    MultiselectWithRenderLimit,
    // SortIcon,
    NotificationSidebar,
    PortalTarget
  },

  mounted () {
    this.onScrollTable()
    this.getNotifications()
    this.getNotificationTemplates()
    this.getBatchScheduledDate()
    this.updateErrorTabEnabled()
    this.onResizeWindow()
    document.addEventListener('mousedown', (event) => {
      if (event?.target?.className === 'actions-items') return

      this.onClickOutside(event)
    })
    this.filteredOptions = this.options.filter(op => op.label !== 'Move to Auto')
    this.filteredBulkOptions = this.bulkOptions.filter(op => op.label !== 'Move to Auto')
  },

  beforeDestroy () {
    store.dispatch('notificationsQueue/resetState')
  },

  data () {
    return {
      currentTab: 0,
      gridSearchCriteria: '',
      firstIndex: 0,
      lastIndex: 15,
      sortAttributes: {},
      gridLoading: true,
      computedList: [],
      defaultSortDirection: 'asc',
      showPreviewModal: false,
      previewModalContent: null,
      showJsonModal: false,
      jsonModalContent: {},
      showValidationErrorModal: false,
      validationErrorContent: '',
      totalCount: 0,
      aggregations: {
        client: [],
        NotificationsTemplateName: [],
        type: [],
        plan: [],
        recipientLastName: [],
        recipientEligibilityType: [],
        networkName: [],
        recipientPcpNpi: [],
        sent: []
      },
      notificationTemplateList: [],
      scheduledDate: null,
      batchScheduledDate: null,
      filterParams: {
        client: [],
        NotificationsTemplateName: [],
        type: [],
        plan: [],
        recipientLastName: [],
        recipientEligibilityType: [],
        networkName: [],
        recipientPcpNpi: [],
        status: [{
          key: 'PENDING'
        }],
        sendType: [{
          key: 'automated'
        }],
        sent: []
      },
      isActionOptionsOpened: false,
      isActionOptionsAbove: false,
      tableFirstVisibleRowIndex: 0,
      options: [
        {
          img: require('../assets/icons/pause-circle-icon.svg'),
          label: 'Move to Manual',
          func: () => this.changeNotificationAutomation({ sendType: 'manual' }),
          messageType: 'MANUAL'
        },
        {
          img: require('../assets/icons/double-play-icon.svg'),
          label: 'Move to Auto',
          func: () => this.changeNotificationAutomation({ sendType: 'automated' }),
          messageType: 'AUTOMATE'
        },
        {
          img: require('../assets/icons/play-circle-icon.svg'),
          label: 'Send now',
          func: () => this.sendNotification({ isBulkAction: false }),
          messageType: 'SEND'
        },
        {
          img: require('../assets/icons/download-icon.svg'),
          label: 'Download CSV',
          func: () => this.downloadNotificationsCSV()
        },
        {
          img: require('../assets/icons/file-text-icon.svg'),
          label: 'View raw data',
          func: () => this.getNotificationData('viewRawData')
        },
        {
          img: require('../assets/icons/trash-icon.svg'),
          label: 'Delete',
          func: () => this.deleteNotifications({ isBulkAction: false }),
          messageType: 'DELETE'
        }
      ],
      filteredOptions: [],
      modalData: null,
      selectedRow: null,
      html: HTMLElement,
      isErrorTabEnabled: false,
      rowStatus: [],
      menuStatusImg: null,
      bulkOptions: [
        {
          img: require('../assets/icons/pause-circle-icon.svg'),
          label: 'Move to Manual',
          func: () => this.changeNotificationAutomation({ sendType: 'manual', isBulkAction: true }),
          messageType: 'MANUAL_BULK'
        },
        {
          img: require('../assets/icons/double-play-icon.svg'),
          label: 'Move to Auto',
          func: () => this.changeNotificationAutomation({ sendType: 'automated', isBulkAction: true }),
          messageType: 'AUTOMATE_BULK'
        },
        {
          img: require('../assets/icons/play-circle-icon.svg'),
          label: 'Send now',
          func: () => this.sendNotification({ isBulkAction: true }),
          messageType: 'SEND_BULK'
        },
        {
          img: require('../assets/icons/download-icon.svg'),
          label: 'Download CSV',
          func: () => this.downloadNotificationsCSV({ isBulkAction: true })
        },
        {
          img: require('../assets/icons/trash-icon.svg'),
          label: 'Delete',
          func: () => this.deleteNotifications({ isBulkAction: true }),
          messageType: 'DELETE_BULK'
        }
      ],
      currentBulkAction: null,
      isBulkOptionsDisabled: false,
      errorRows: {},
      bulkResultStatus: undefined,
      bulkResultText: '',
      filteredBulkOptions: [],
      topPosition: 0,
      leftPosition: 0,
      detailRowOpened: [],
      openedRowId: null
    }
  },

  computed: {
    list: {
      get () {
        return this.computedList
      },
      set (value) {
        this.computedList = value
      }
    },
    searchParam: {
      get () {
        return this.gridSearchCriteria
      },
      set (value) {
        this.gridSearchCriteria = value
      }
    }
  },

  methods: {
    search: _.debounce(async function ({ updateAggregations }) {
      this.getFilterAndSearchResults({ updateAggregations })
    }, 400),

    searchClientOrTemplate: _.debounce(async function () {
      this.getFilterAndSearchResults({ updateAggregations: true })
    }, 400),

    searchByMultiselect: _.debounce(async function () {
      this.getFilterAndSearchResults({ updateAggregations: true, multiselect: true })
    }, 400),

    onOpenConfirmationModal (option) {
      this.modalData = {}
      this.modalData.title = NotificationRowModalMessage[`${option.messageType}_TITLE`]
      this.modalData.text = NotificationRowModalMessage[`${option.messageType}_TEXT`]
      this.modalData.btn = NotificationRowModalMessage[`${option.messageType}_BTN`]
      this.currentBulkAction = option.messageType
      this.modalData.func = option.func
      this.isActionOptionsOpened = false
    },

    onScrollTable () {
      const tableElm = this.$refs.notificationTable.$el.getElementsByTagName('div')[0]

      tableElm.addEventListener('scroll', e => {
        this.isActionOptionsOpened = false

        // Workaround to fix scrollTop when zooming out: assign the ceiling value to the scrollTop when the sum between scrollTop + clientHeight doesn't achieve the scrollHeight.
        const scrollTop = tableElm.scrollTop + tableElm.clientHeight >= tableElm.scrollHeight
          ? tableElm.scrollTop
          : Math.ceil(tableElm.scrollTop)

        if ((scrollTop + tableElm.clientHeight >= tableElm.scrollHeight) && (this.list?.length < this.totalCount)) {
          this.getNotifications({ isScroll: true, updateAggregations: false })
        }

        this.tableFirstVisibleRowIndex = Math.ceil(tableElm.scrollTop / 60 /* height of the row */) - 1
      })
    },

    async downloadNotificationsCSV (params = {}) {
      const tableRowData = ['client', 'NotificationsTemplateName', 'type', 'plan', 'recipientFamilyId', 'recipientFirstName', 'recipientLastName', 'recipientEligibilityType', 'networkName', 'recipientPcpNpi', 'Pcp_Name', 'created']

      let downloadName = 'all-notifications-csv'
      let csvData = ''

      if (params.isBulkAction) {
        const listOfItems = await store.dispatch('notificationsQueue/getBulkNotificationsData')
        const filteredItems = []

        listOfItems.forEach((item) => {
          item = {
            ...item,
            Pcp_Name: item.data.PCP_NAME?.toLowerCase().includes('null') ? 'N/A' : item.data.PCP_NAME
          }
          filteredItems.push(
            sanitizeObj(
              _.pick(item, tableRowData),
              tableRowData
            )
          )
        })

        csvData = convertToCSV(filteredItems)
      } else {
        downloadName = `${this.selectedRow.client}-${this.selectedRow.NotificationsTemplateName}`
        let modifiedRow = this.selectedRow
        modifiedRow = {
          ...modifiedRow,
          Pcp_Name: modifiedRow.data.PCP_NAME?.toLowerCase().includes('null') ? 'N/A' : modifiedRow.data.PCP_NAME
        }

        const parsedObj = sanitizeObj(
          _.pick(
            _.cloneDeep(modifiedRow),
            tableRowData
          ),
          tableRowData
        )

        csvData = convertToCSV([parsedObj])
      }
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
      anchor.target = '_blank'
      anchor.download = params.isBulkAction ? 'all-notifications-csv' : downloadName
      anchor.click()
    },

    resetFilters (shouldSearch = true) {
      this.filterParams = {
        client: [],
        NotificationsTemplateName: [],
        plan: [],
        ...tabFilters[this.currentTab]
      }
      this.sortAttributes = {}

      if (shouldSearch) {
        this.$nextTick(() => {
          this.search({ updateAggregations: true })
        })
      }
    },

    resetScroll () {
      const tableElm = this.$refs.notificationTable.$el.getElementsByTagName('div')[0]
      tableElm.scrollTop = 0
    },

    getFilterAndSearchResults (args = {}) {
      const { updateAggregations = true, multiselect = false } = args

      this.gridLoading = true
      this.resetScroll()
      const searchCriteria = this.searchParam?.length > 0 ? this.searchParam : null
      const filterParams = Object.keys(this.filterParams).reduce((acc, curr) => {
        if (this.filterParams[curr].length === 0) {
          return acc
        }

        return {
          ...acc,
          [curr]: this.filterParams[curr].map(({ key }) => key).join(',')
        }
      }, {})

      if (store.getters['notificationsQueue/cancelTokenSource']) {
        store.dispatch('notificationsQueue/cancelSearchRequest')
      }

      store
        .dispatch('notificationsQueue/getSearchResults', { searchCriteria, filterParams, sortAttributes: this.sortAttributes, aggs, multiselect })
        .then(() => {
          this.list = store.getters['notificationsQueue/queue']
          this.totalCount = store.getters['notificationsQueue/totalCount']

          if (updateAggregations) {
            this.aggregations = store.getters['notificationsQueue/aggregations']
          }
          this.isBulkOptionsDisabled = !this.list || this.list.length === 0
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.gridLoading = false
        })
    },

    resetActionsState (tab) {
      this.currentTab = tab
      this.resetFilters(false)
      this.errorRows = {}
      this.bulkResultStatus = undefined
      this.bulkResultText = ''
      this.rowStatus = []
      this.menuStatusImg = null
      this.isBulkOptionsDisabled = false
    },

    async onChangeTab (tab) {
      this.resetActionsState(tab)

      switch (tab) {
        case 0:
          this.scheduledDate = this.batchScheduledDate
          this.filterParams = { ...this.filterParams, ...tabFilters[tab] }
          this.filteredOptions = this.options.filter(op => op.label !== 'Move to Auto')
          this.filteredBulkOptions = this.bulkOptions.filter(op => op.label !== 'Move to Auto')
          this.updateErrorTabEnabled()
          break
        case 1:
          this.scheduledDate = 'Paused'
          this.filterParams = { ...this.filterParams, ...tabFilters[tab] }
          this.filteredOptions = this.options.filter(op => op.label !== 'Move to Manual')
          this.filteredBulkOptions = this.bulkOptions.filter(op => op.label !== 'Move to Manual')
          this.updateErrorTabEnabled()
          break
        case 2:
          this.scheduledDate = null
          this.filterParams = { ...this.filterParams, ...tabFilters[tab] }
          this.filteredBulkOptions = this.bulkOptions.filter(op => op.label === 'Download CSV')
          this.updateErrorTabEnabled()
          break
        case 3:
          this.scheduledDate = 'Error'
          this.filterParams.status = [{ key: 'ERROR' }]
          this.filterParams.sendType = []
          this.filteredOptions = this.options.filter(op => op.label !== 'Move to Manual')
          this.filteredBulkOptions = this.bulkOptions.filter(op => op.label !== 'Move to Manual')
          break
        default:
      }
      this.$nextTick(() => {
        this.getFilterAndSearchResults({ updateAggregations: true })
      })
    },
    getRowStatus (rowKey) {
      return this.rowStatus.find(({ key }) => key === rowKey)
    },

    shouldShowActionIcon (rowKey) {
      const status = this.getRowStatus(rowKey)

      return status && status.img !== null
    },

    onSortRaw (e) {
      const field = e.target.id

      const { sortField, sortDir } = this.sortAttributes

      if (sortField !== field) {
        this.sortAttributes = { sortField: field, sortDir: 'asc' }
      } else if (sortDir === 'asc') {
        this.sortAttributes = { sortField: field, sortDir: 'desc' }
      } else {
        this.sortAttributes = {}
      }

      this.$nextTick(() => {
        this.search({ updateAggregations: false })
      })
    },

    onSort ({ sortField, sortDir }) {
      if (sortDir === '') {
        this.sortAttributes = {}
      } else {
        this.sortAttributes = { sortField, sortDir }
      }

      this.$nextTick(() => {
        this.search({ updateAggregations: false })
      })
    },

    getBatchScheduledDate () {
      store
        .dispatch('notificationsQueue/getBatchScheduledDate')
        .then(() => {
          this.batchScheduledDate = store.getters['notificationsQueue/batchScheduledDate']
          this.scheduledDate = this.batchScheduledDate
        })
        .catch((error) => {
          console.error(error)
          this.$buefy.toast.open({
            duration: 10000,
            message: 'Loading the batch scheduled date failed',
            type: 'is-danger',
            pauseOnHover: true
          })
        })
    },
    getNotificationTemplates () {
      store
        .dispatch('notificationsQueue/getNotificationTemplateList')
        .then(() => {
          this.notificationTemplateList = store.getters['notificationsQueue/notificationTemplateList']
        })
    },

    getNotifications (args = {}) {
      const { isScroll = false, updateAggregations = true } = args

      this.gridLoading = true
      store
        .dispatch(
          'notificationsQueue/fetchNotificationsQueue',
          {
            isScroll,
            aggs
          }
        )
        .then(() => {
          this.list = store.getters['notificationsQueue/queue']
          this.totalCount = store.getters['notificationsQueue/totalCount']

          if (updateAggregations) {
            this.aggregations = store.getters['notificationsQueue/aggregations']
          }
          this.isBulkOptionsDisabled = !this.list || this.list.length === 0
        })
        .catch((error) => {
          console.error(error)
          this.$buefy.toast.open({
            duration: 10000,
            message: 'Loading the message queue failed',
            type: 'is-danger',
            pauseOnHover: true
          })
        })
        .finally(() => {
          this.gridLoading = false
        })
    },

    updateErrorTabEnabled () {
      store.dispatch('notificationsQueue/updateErrorTabEnabled')
        .then(() => {
          this.isErrorTabEnabled = store.getters['notificationsQueue/errorTabEnabled']
        })
        .catch(console.error)
    },

    /** ACTIONS */

    initiateNotificationAction ({ isAction = true, row = null, isBulkAction = false }) {
      this.isActionOptionsOpened = false
      this.modalData = null

      if (isAction) {
        this.updateActionIcon({ statusImg: require('../assets/icons/loading-spinner.svg'), isBulkAction })
      }

      if (isBulkAction) {
        return null
      }

      return handleNotificationsRecords(row ?? this.selectedRow)
    },

    handleBulkActionResult ({ error, result, isBulkAction, actionType }) {
      const showError = (message) => {
        this.bulkResultStatus = 'error'
        this.bulkResultText = message
        this.updateActionIcon({ statusImg: require('../assets/icons/error.svg'), isBulkAction })
      }

      const showSuccess = () => {
        this.updateActionIcon({ statusImg: require('../assets/icons/check-circle.svg'), isBulkAction })
      }

      if (!result?.bulkResult && !error?.bulkResult) {
        showError('A critical error occured.')
        return
      }

      let failCount = 0
      for (const element of (result.bulkResult ?? error.bulkResult ?? [])) {
        if (!element.success && !element.value?.success) failCount++
      }

      if (failCount > 0) {
        const isErrorTab = actionType !== 'changeNotificationAutomation'
        showError(`${failCount} notification${failCount !== 1 ? 's' : ''} errored. ${isErrorTab ? 'Go check the error tab. ' : ''}Refresh the page if it does not reset.`)
      } else {
        showSuccess()
      }
    },

    onSuccessAction ({ isBulkAction = false, result, actionType }) {
      if (this.totalCount === 1) {
        this.isBulkOptionsDisabled = true
      }

      if (isBulkAction) {
        this.isBulkOptionsDisabled = true
        this.handleBulkActionResult({ result, isBulkAction, actionType })
      } else {
        this.updateActionIcon({ statusImg: require('../assets/icons/check-circle.svg'), isBulkAction })
      }
    },

    onErrorAction ({ errorMsg, isBulkAction = false, rowId, error }) {
      this.updateActionIcon({ statusImg: require('../assets/icons/error.svg'), isBulkAction })

      if (this.totalCount === 1) {
        this.isBulkOptionsDisabled = true
      }

      if (!isBulkAction) {
        this.updateActionIcon({ statusImg: null, isBulkAction })
        this.errorRows[rowId] = { error: errorMsg }
      } else {
        this.isBulkOptionsDisabled = true
        this.handleBulkActionResult({ error })
      }
      this.isErrorTabEnabled = true
    },

    changeNotificationAutomation ({ sendType, isBulkAction = false }) {
      const notification = this.initiateNotificationAction({ isBulkAction })

      store
        .dispatch('notificationsQueue/changeNotificationAutomation', { notification, sendType })
        .then((result) => {
          this.onSuccessAction({ isBulkAction, result, actionType: 'changeNotificationAutomation' })
        })
        .catch((error) => {
          this.onErrorAction({
            errorMsg: 'Something went wrong when changing notification sending type',
            isBulkAction,
            error,
            rowId: notification?.id ?? ''
          })
        })
    },

    getNotificationData (actionFunc, props) {
      const notification = this.initiateNotificationAction({ isAction: false, row: props?.row })

      store
        .dispatch('notificationsQueue/getNotificationData', { notification })
        .then((res) => {
          this[actionFunc](res)
        })
        .catch((error) => {
          this.onErrorAction({
            errorMsg: `There was an issue getting the details for this notification: ${error?.error ?? 'Unknown error'}`,
            rowId: notification?.id
          })
        })
        .finally(() => {
          this.gridLoading = false
        })
    },

    sendNotification ({ isBulkAction }) {
      const notification = this.initiateNotificationAction({ isBulkAction })

      store
        .dispatch('notificationsQueue/sendNotifications', { notification })
        .then((result) => {
          this.onSuccessAction({ isBulkAction, result })
        })
        .catch((error) => {
          this.onErrorAction({
            errorMsg: `There was an issue sending this notification: ${error?.error ?? 'Unknown error'}`,
            isBulkAction,
            error,
            rowId: notification?.id ?? ''
          })
        })
    },

    deleteNotifications ({ isBulkAction }) {
      const notification = this.initiateNotificationAction({ isBulkAction })

      store
        .dispatch('notificationsQueue/deleteNotification', { notification })
        .then((result) => {
          this.onSuccessAction({ isBulkAction, result })
        })
        .catch((error) => {
          this.onErrorAction({
            errorMsg: `There was an issue deleting this notification: ${error?.error ?? 'Unknown error'}`,
            isBulkAction,
            error,
            rowId: notification?.id ?? ''
          })
        })
    },

    previewMessage (notification) {
      if (notification.validationError) {
        this.showValidationErrorModal = !this.showValidationErrorModal
        this.validationErrorContent = notification.validationError
      } else {
        const content = notification.emailTemplate ?? notification.message
        const replaceBreaks = content.replace(/{{break}}/g, '<br/>')
        const parser = new DOMParser()

        if (notification.message) {
          this.onFormatMessagePreview(notification, replaceBreaks, parser)
        }

        if (notification.emailTemplate) {
          this.html = parser.parseFromString(replaceBreaks, 'text/html')
        }

        this.showPreviewModal = !this.showPreviewModal
        this.previewModalContent = this.html.querySelector('body').outerHTML
      }
    },

    viewRawData (notification) {
      this.jsonModalContent = notification
      if (this.jsonModalContent.emailTemplate) {
        this.jsonModalContent.emailTemplate = `${this.jsonModalContent.emailTemplate.slice(0, 300)}...`
      }
      this.showJsonModal = !this.showJsonModal
    },

    /** FORMATTERS  */

    getFormattedDate (value) {
      const date = new Date(value)
      if (date.toString() !== 'Invalid Date' && value !== 0) {
        return `${date.toLocaleDateString('en-US')} ${date.toLocaleTimeString('en-US')}`
      }

      return 'N/A'
    },

    getFormattedString: (value) => value ?? 'N/A',

    /** Actions dropdown showing logic */

    updateActionIcon ({ statusImg, isBulkAction = false }) {
      if (isBulkAction) {
        this.menuStatusImg = statusImg
        return
      }
      const existingStatusIndex = this.rowStatus.findIndex(({ key }) => key === this.selectedRow?.sk)

      if (existingStatusIndex !== -1) {
        this.rowStatus[existingStatusIndex].img = statusImg
      } else {
        this.rowStatus.push({ key: this.selectedRow?.sk, img: statusImg })
      }
    },

    showActionOptions (event, props) {
      this.selectedRow = props?.row
      this.isActionOptionsAbove = this.list?.length >= 4 && props.index - this.tableFirstVisibleRowIndex >= 5

      this.topPosition = event.pageY + 20
      this.leftPosition = event.pageX - 135

      this.isActionOptionsOpened = !this.isActionOptionsOpened
    },

    onResizeWindow () {
      window.onresize = () => {
        this.isActionOptionsOpened = false
      }
    },

    onClickOutside (e) {
      this.$nextTick()
      const actionsOptionsIcon = this.$refs.actionsOptionsIcon

      const isIconClick = actionsOptionsIcon?.firstChild?.outerHTML === e.target.outerHTML

      if (!isIconClick) {
        this.isActionOptionsOpened = false
      }
    },

    onDetailsRowOpen (row) {
      this.detailRowOpened = [row]
      this.openedRowId = row.id
    },

    onDetailsRowClose () {
      this.openedRowId = null
      this.detailRowOpened = []
    },

    getParentRowStyle (row) {
      return {
        'parent-row-highlight': row.id === this.openedRowId
      }
    },

    getParentCellStyle (row) {
      return {
        style: {
          'border-left': row.id === this.openedRowId ? '3px solid #5586EF' : ''
        }
      }
    },

    onFormatMessagePreview (notification, replaceBreaks, parser) {
      const title = document.createElement('h2')
      title.textContent = notification.subject
      this.html = parser.parseFromString(replaceBreaks, 'text/html')
      this.html.body.prepend(title)

      const messageTitle = this.html.querySelector('h2')
      messageTitle.style.cssText = 'color: #1F124F;'
    },

    onChangeSendType: _.debounce(function (template) {
      store.dispatch('notificationsQueue/updateNotificationTemplateList', template).then(updatedTemplate => {
        const optIndex = this.notificationTemplateList.findIndex(({ template }) => template === updatedTemplate.template)

        this.notificationTemplateList[optIndex] = updatedTemplate
      })
    }, 200),

    dateConverter (dates) {
      const fullDate = dates.map(date => ({
        ...date,
        label: fullDateToDate(date.key)
      }))

      return fullDate
    }
  }
}

</script>

<style lang="scss">
.notifications-queue--content {
  display: flex;
  font-family: 'Centivo-Regular';
  height: calc(100vh - 5.625rem);
  min-height: 950px
}

.notifications-queue--root {
  width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.notifications-queue--header {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  padding-top: 3.5rem;

  .notifications-queue--subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .tabs-and-search {
    margin-bottom: 2.125rem;
  }

  .count-and-scheduled {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    #totalCount {
      margin-right: 1.25rem;
    }

    label {
      font-weight: 500;
      margin-right: 0.5rem;
    }

    span {
      font-weight: 700;
      margin-left: 0.5rem;
    }
  }

  > .control {
    width: 480px;
  }

  > a {
    display: flex;
    align-items: center;
    color: #ec008c;
    font-weight: 600;

    img {
      margin-left: 0.375rem;
    }
  }
}

.b-table {
  .table-wrapper {
      &.has-sticky-header {
          max-height: 620px;
          height: auto;
          overflow-x: scroll;
      }
  }
}

.notifications-queue--table {
  border-bottom: 1px solid #EBEBEB;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;

  .table-wrapper table {
    th {
      border: none;
      background: #EBEFF5 !important;
      font-weight: 500;
      padding: 0.5rem;
      color: #212121;

      svg {
        color: #7A7E81;
        margin-left: 0.3rem;
      }
    }

    tr {
      // Not great, but the class name is not updated after
      // the first render
      &:has(.row-error-message), &:has(.row-error-message) td {
        background-color: #FCEDED;
      }

      &:hover {
        .row-error-message {
          display: block;
        }
      }

      &.parent-row-highlight {
        background-color: #F6F7FA;
      }

      &.detail {
        background-color: #FFFFFF;
        box-shadow: inset 0 0.5px 0 #dbdbdb;
        td {
          border-top: none;
          box-shadow: none;
          border-bottom: 1px solid #EBEFF5;
          border-right: 2px solid #EBEFF5;
          border-left: 3px solid #5586EF;
          border-radius: 0;

          div.detail-container {
            padding: 0;
            line-height: 22px;

            .expanded-row-content {
              margin-left: 14px;
            }

            .row-item-pcpname {
              margin-left: 52px;
            }
          }
        }
      }
    }

    td {
      text-align: left;
      vertical-align: middle;
      padding-left: 8px;
      height: 60px;
      line-height: 22px;
      color: #212121;

      .status-action-wrapper {
        display: flex;
        justify-content: center;

        img.spinner {
          animation: rotation 2s infinite linear;
        }
      }

      &:hover {
        .row-error-message {
          display: none;
        }
      }

      .actions-wrapper {
        .column-icons {
          margin-bottom: 0;
        }

        .column-icons .field {
          padding: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          text-align: center;

          button {
            cursor: pointer;
            border: none;
            background-color: transparent;

            &:disabled {
              opacity: 0.6;
              cursor: not-allowed;
            }
          }

          .icons-container {
            margin: 0;
          }

          .button-preview {
            &:disabled {
              opacity: 0.4;
              cursor: not-allowed;
            }
          }
        }
      }

      .row-error-message {
        display: none;
        position: relative;

        > div.message {
          position: absolute;
          top: -2.25rem;
          left: -37.5rem;
          width: 37rem;
          z-index: 999;
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: red;
          color: white;
          font-size: 0.85rem;

          img {
            filter: saturate(0) brightness(0) invert(1);
            height: 1.5rem;
            aspect-ratio: 1;
            margin: 0.25rem 0.75rem;
          }
        }
      }
    }
  }

  .custom-status-text {
    &.Published {
      color: #3BB02E;
    }

    &.Not\ Started {
      color: #444;
    }

    &.Unpublished {
      color: #7A7E81;
    }
  }
}

.actions-options-container {
  position: absolute;
  width: 161px;
  border: 1px solid #aeb9cb;
  border-radius: 4px;
  background: #fff;

  > img {
    position: absolute;
    top: -9px;
    right: 12px;
    width: 21px;
    height: 10px;
  }

  .actions-items {
    height: 45px;
    display: flex;
    align-items: center;
    gap: 9px;
    padding-left: 14px;
    font-family: 'Regular', sans-serif;
    font-weight: 500;
    text-align: initial;
    cursor: pointer;
  }

  &.inverted {
    > img {
      transform: rotate(180deg);
      top: 224px;
    }
  }
}

.spinner {
  animation: rotation 2s infinite linear;
}

.notifications-bulk-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 3rem;
  padding: 16px 12px;
  background: #F6F7FA;
  border: 1px solid #D8D8D8;

  > ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    button {
      display: flex;
      flex-direction: row;
      gap: 8px;
      padding: 8px 14px;
      border: 1px solid #5586EF;
      border-radius: 4px;
      align-items: center;
      background: #FFF;
      font-family: 'Regular', sans-serif;
      font-size: 1rem;
      font-weight: 600;
      color: #5586EF;
      cursor: pointer;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  > div.bulk-result {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FCEDED;
    border: red solid 1px;
    border-radius: 4px;
    color: #444;
    font-size: 1rem;
    font-weight: 700;
    max-width: 26.5rem;
    text-align: left;

    img {
      filter: invert(15%) sepia(50%) saturate(7443%) hue-rotate(7deg) brightness(111%) contrast(136%);
      height: 1.5rem;
      aspect-ratio: 1;
      margin: 0.25rem 0.75rem;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;

      img {
        filter: invert(50%) sepia(12%) saturate(462%) hue-rotate(177deg) brightness(93%) contrast(90%);
        height: 0.8rem;
      }
    }

    &.success {
      background-color: #ECF7EB;
      border-color: #3BB02E;
    }
  }
}

.big-screen-modal {
  padding-top: 0;
  .modal-content {
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    iframe {
      width: 100%;
      height: 80vh;
    }
  }
}

.notifications-queue--search {
  .control.has-icons-left .input {
    padding-left: 2.75rem;
  }

  input.is-medium.input {
    width: 27.438rem;
    height: 3.125rem;
    color: #909DB0;
    font-family: 'Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
}

.refresh-list {
  color: #59616E;
}

.sortable-header  {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;

  &:focus,
  &:active {
    outline: none;
  }

  > div {
    display: contents;
  }

  > span {
    pointer-events: none;
  }
}

.reset-filter--button {
  background-color: transparent;
  border: none;
  color: #5586EF;
  font-size: 1rem;
  padding: calc(0.5rem - 1px) 1rem;
  line-height: 1.625rem;
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.th-wrap span, .th-wrap button {
  font-size: 0.875rem;
}

.client-row-click {
  a {
    color: #5586EF;
  }
}

</style>
